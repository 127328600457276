"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainThreadStorageWriterHandler = void 0;
var MainThreadStorageWriterHandler = (function () {
    function MainThreadStorageWriterHandler(storageWriter) {
        this.storageWriter = storageWriter;
    }
    MainThreadStorageWriterHandler.prototype.postMessage = function (msg) {
        this.storageWriter.handleMessage(msg);
    };
    return MainThreadStorageWriterHandler;
}());
exports.MainThreadStorageWriterHandler = MainThreadStorageWriterHandler;
