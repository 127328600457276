"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryStorage = void 0;
var InMemoryStorage = (function () {
    function InMemoryStorage() {
        this.inMemoryStorage = {};
    }
    InMemoryStorage.prototype.clear = function () {
        this.inMemoryStorage = {};
    };
    InMemoryStorage.prototype.getItem = function (key) {
        if (Object.prototype.hasOwnProperty.call(this.inMemoryStorage, key)) {
            return this.inMemoryStorage[key];
        }
        return null;
    };
    InMemoryStorage.prototype.key = function (index) {
        var _a;
        return (_a = Object.keys(this.inMemoryStorage)[index]) !== null && _a !== void 0 ? _a : null;
    };
    InMemoryStorage.prototype.removeItem = function (key) {
        delete this.inMemoryStorage[key];
    };
    InMemoryStorage.prototype.setItem = function (key, value) {
        this.inMemoryStorage[key] = String(value);
    };
    Object.defineProperty(InMemoryStorage.prototype, "length", {
        get: function () {
            return Object.keys(this.inMemoryStorage).length;
        },
        enumerable: false,
        configurable: true
    });
    return InMemoryStorage;
}());
exports.InMemoryStorage = InMemoryStorage;
