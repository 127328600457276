"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageGuard = void 0;
var StorageGuard = (function () {
    function StorageGuard(api, device, options) {
        this.api = api;
        this.device = device;
        this.options = options;
        this.isAppValid = true;
        this.isDeviceEnabled = false;
        var checkIsDeviceEnabledIntervalMs = 5 * 60 * 1000;
        this.checkIsDeviceEnabled();
        setInterval(this.checkIsDeviceEnabled.bind(this), checkIsDeviceEnabledIntervalMs);
    }
    StorageGuard.prototype.canProcess = function (force) {
        return this.isAppValid && (this.isDeviceEnabled || force);
    };
    StorageGuard.prototype.canWrite = function () {
        return this.isAppValid && this.isDeviceEnabled;
    };
    StorageGuard.prototype.checkIsDeviceEnabled = function () {
        var _this = this;
        var deviceInfo = this.device.getInfo(this.options);
        return this.api.getDeviceStatus(deviceInfo).then(function (status) {
            _this.isAppValid = status.isAppValid;
            _this.isDeviceEnabled = status.isDeviceEnabled;
        }, function () {
        });
    };
    return StorageGuard;
}());
exports.StorageGuard = StorageGuard;
