"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterUIEventsHandler = void 0;
var register_events_handler_base_1 = require("./register-events-handler.base");
var RegisterUIEventsHandler = (function (_super) {
    __extends(RegisterUIEventsHandler, _super);
    function RegisterUIEventsHandler(storage, window) {
        var _this = _super.call(this, window) || this;
        _this.storage = storage;
        _this.window = window;
        return _this;
    }
    RegisterUIEventsHandler.prototype.init = function () {
        var _this = this;
        if (!this.window.document.addEventListener) {
            return;
        }
        var options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        document.addEventListener('click', function (e) {
            if (e.target instanceof Element) {
                _this.storage.addLogEntry(_this.getLog(e.target, 'click'));
            }
        }, options);
        document.addEventListener('focusin', function (e) {
            if (e.target instanceof Element) {
                _this.storage.addLogEntry(_this.getLog(e.target, 'focus'));
            }
        }, options);
        document.addEventListener('focusout', function (e) {
            if (e.target instanceof Element) {
                _this.storage.addLogEntry(_this.getLog(e.target, 'blur'));
            }
        }, options);
        this.window.addEventListener('submit', function (e) {
            _this.storage.addLogEntry(_this.getLog(e.target, 'submit'));
        }, options);
    };
    RegisterUIEventsHandler.prototype.getLog = function (target, eventName) {
        return {
            tag: 'js_event',
            text: JSON.stringify(this.getEventData(target, eventName)),
            url: this.getURL(),
        };
    };
    RegisterUIEventsHandler.prototype.getPathTo = function (element) {
        if (element.id !== '') {
            return 'id("' + element.id + '")';
        }
        if (element === document.body) {
            return element.tagName;
        }
        if (element.tagName === 'HTML') {
            return element.tagName;
        }
        var ix = 0;
        var siblings = element.parentNode.childNodes;
        for (var i = 0; i < siblings.length; i++) {
            var sibling = siblings[i];
            if (sibling === element) {
                return this.getPathTo(element.parentNode) + '/' + element.tagName + '[' + (ix + 1) + ']';
            }
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
                ix++;
            }
        }
    };
    RegisterUIEventsHandler.prototype.getEventData = function (target, eventName) {
        if (target instanceof Element) {
            var text = (target.childElementCount <= 1) ? target.textContent : undefined;
            var form = (target.tagName === 'FORM') ? target : null;
            return {
                event: eventName,
                url: (this.window) ? this.window.location.href : '',
                xpath: this.getPathTo(target),
                tag: target.tagName,
                id: target.id,
                text: text,
                action: (form && form.action) ? form.action : '',
            };
        }
        return null;
    };
    return RegisterUIEventsHandler;
}(register_events_handler_base_1.RegisterEventsHandlerBase));
exports.RegisterUIEventsHandler = RegisterUIEventsHandler;
