"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterSanitizer = void 0;
var log_1 = require("./types/log");
var StorageWriterSanitizer = (function () {
    function StorageWriterSanitizer() {
    }
    StorageWriterSanitizer.prototype.toString = function (value) {
        var isToString = ['string', 'symbol', 'bigint', 'function'].includes(typeof value) || value instanceof Error;
        if (isToString) {
            try {
                return String(value);
            }
            catch (_a) {
                return JSON.stringify(value);
            }
        }
        else {
            try {
                return JSON.stringify(value);
            }
            catch (_b) {
                return String(value);
            }
        }
    };
    StorageWriterSanitizer.prototype.toNumber = function (value) {
        value = Number(value);
        if (!isNaN(value)) {
            return value;
        }
        else {
            throw new Error("Can't be casted to number");
        }
    };
    StorageWriterSanitizer.prototype.sanitize = function (message) {
        var _this = this;
        switch (message.type) {
            case 'device-key': {
                var isValidValue = ['boolean', 'number', 'string'].includes(typeof message.data.value);
                message.data.key = this.toString(message.data.key);
                if (!isValidValue) {
                    message.data.value = this.toString(message.data.value);
                }
                break;
            }
            case 'issue': {
                message.data.title = this.toString(message.data.title);
                message.data.markdown = this.toString(message.data.markdown);
                break;
            }
            case 'log-entry': {
                var strings = ['tag', 'method', 'file', 'text', 'url'];
                var hasLine = 'line' in message.data.log && typeof message.data.log.line !== 'undefined';
                var isValidLine = hasLine && typeof message.data.log.line === 'number';
                var hasLevel = 'level' in message.data.log && typeof message.data.log.level !== 'undefined';
                var isValidLevel = hasLevel && log_1.LOG_LEVELS.includes(message.data.log.level);
                strings.forEach(function (key) {
                    var hasValue = key in message.data.log && typeof message.data.log[key] !== 'undefined';
                    if (hasValue) {
                        try {
                            message.data.log[key] = _this.toString(message.data.log[key]);
                        }
                        catch (_a) {
                            delete message.data.log[key];
                        }
                    }
                });
                if (hasLine && !isValidLine) {
                    try {
                        message.data.log.line = this.toNumber(message.data.log.line);
                    }
                    catch (_a) {
                        delete message.data.log.line;
                    }
                }
                if (hasLevel && !isValidLevel) {
                    try {
                        message.data.log.level = this.toNumber(message.data.log.level);
                        if (!log_1.LOG_LEVELS.includes(message.data.log.level)) {
                            delete message.data.log.level;
                        }
                    }
                    catch (_b) {
                        delete message.data.log.level;
                    }
                }
                break;
            }
        }
        return message;
    };
    return StorageWriterSanitizer;
}());
exports.StorageWriterSanitizer = StorageWriterSanitizer;
