"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureDetection = void 0;
var utils_1 = require("./utils");
var cache = new Map();
var Cached = utils_1.CacheDecoratorFactory(cache);
var FeatureDetection = (function () {
    function FeatureDetection(indexedDB, localStorage, sessionStorage, broadcastChannel, worker) {
        this.indexedDB = indexedDB;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.broadcastChannel = broadcastChannel;
        this.worker = worker;
    }
    FeatureDetection.prototype.isBroadcastChannelSupported = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = typeof this.broadcastChannel === 'function';
                        if (_a) return [3, 2];
                        return [4, this.isIndexedDBSupported()];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2: return [2, _a || this.isLocalStorageSupported()];
                }
            });
        });
    };
    FeatureDetection.prototype.isIndexedDBSupported = function () {
        var _this = this;
        return new Promise(function (resolve, _reject) {
            var openReq = _this.indexedDB.open('bf_sdk_test');
            openReq.onerror = function () { return resolve(false); };
            openReq.onsuccess = function () {
                resolve(true);
                var db = openReq.result;
                db.close();
            };
        });
    };
    FeatureDetection.prototype.isLocalStorageSupported = function () {
        return this.isStorageSupported(this.localStorage);
    };
    FeatureDetection.prototype.isMultiTabSupported = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.isBroadcastChannelSupported()];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3, 3];
                        return [4, this.isIndexedDBSupported()];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3: return [2, (_a)];
                }
            });
        });
    };
    FeatureDetection.prototype.isSessionStorageSupported = function () {
        return this.isStorageSupported(this.sessionStorage);
    };
    FeatureDetection.prototype.isWorkerSupported = function () {
        return !!this.worker;
    };
    FeatureDetection.prototype.isStorageSupported = function (storage) {
        try {
            var testKey = 'Bf8taM%GhLkg';
            storage.setItem(testKey, testKey);
            storage.removeItem(testKey);
            return true;
        }
        catch (e) {
            return false;
        }
    };
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], FeatureDetection.prototype, "isBroadcastChannelSupported", null);
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], FeatureDetection.prototype, "isIndexedDBSupported", null);
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], FeatureDetection.prototype, "isLocalStorageSupported", null);
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], FeatureDetection.prototype, "isMultiTabSupported", null);
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], FeatureDetection.prototype, "isSessionStorageSupported", null);
    __decorate([
        Cached(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], FeatureDetection.prototype, "isWorkerSupported", null);
    return FeatureDetection;
}());
exports.FeatureDetection = FeatureDetection;
