"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogLevel = exports.BugfenderClass = exports.Bugfender = void 0;
var bugfender_1 = require("./bugfender");
Object.defineProperty(exports, "BugfenderClass", { enumerable: true, get: function () { return bugfender_1.Bugfender; } });
var log_1 = require("./types/log");
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return log_1.LogLevel; } });
var sdk_options_1 = require("./sdk-options");
var sdk_provider_1 = require("./sdk.provider");
var feature_detection_1 = require("./feature-detection");
var featureDetection = new feature_detection_1.FeatureDetection(window.indexedDB, window.localStorage, window.sessionStorage, window.BroadcastChannel, window.Worker);
var provider = new sdk_provider_1.SDKProvider(featureDetection, SDK_USER_AGENT, VERSION, window);
var Bugfender = new bugfender_1.Bugfender(featureDetection, provider, new sdk_options_1.SDKOptions(), window);
exports.Bugfender = Bugfender;
