"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionFactory = void 0;
var SessionFactory = (function () {
    function SessionFactory(device, sdkVersion, window) {
        this.device = device;
        this.sdkVersion = sdkVersion;
        this.window = window;
    }
    SessionFactory.prototype.create = function (uuid, options) {
        return {
            device: this.device.getInfo(options),
            orientation: 0,
            batteryLevel: -1,
            ram_total: 0,
            ram_free: 0,
            ram_used: 0,
            language: this.window.navigator.language,
            time: new Date().toISOString(),
            sdk_type: 'web',
            sdk_version: this.sdkVersion,
            session_uid: uuid,
            app_version: {
                app: { key: options.appKey },
                version: options.version,
                build: options.build,
            },
        };
    };
    return SessionFactory;
}());
exports.SessionFactory = SessionFactory;
