"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageProcessor = void 0;
var StorageProcessor = (function () {
    function StorageProcessor(api, channel, connection, logger, device, deviceKeysTable, issuesTable, logsTable, options, sessionFactory, sessionsTable, storageGuard) {
        var _this = this;
        this.api = api;
        this.channel = channel;
        this.connection = connection;
        this.logger = logger;
        this.device = device;
        this.deviceKeysTable = deviceKeysTable;
        this.issuesTable = issuesTable;
        this.logsTable = logsTable;
        this.options = options;
        this.sessionFactory = sessionFactory;
        this.sessionsTable = sessionsTable;
        this.storageGuard = storageGuard;
        this.BATCH_SIZE = 500;
        this.runIntervalMs = 15000;
        this.forceSend = false;
        this.isRunning = false;
        setInterval(this.run.bind(this), this.runIntervalMs);
        this.channel.onHasBecomeLeader().then(function () {
            _this.run();
        });
        this.channel.onForceSend(function () {
            _this.forceSend = true;
        });
    }
    StorageProcessor.prototype.cleanupSessions = function (sessions) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, sessions_1, session, hasActiveTab, hasData, shouldDelete, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, sessions_1 = sessions;
                        _a.label = 1;
                    case 1:
                        if (!(_i < sessions_1.length)) return [3, 8];
                        session = sessions_1[_i];
                        return [4, this.channel.isSessionActive(session.uuid)];
                    case 2:
                        hasActiveTab = _a.sent();
                        return [4, this.hasData(session)];
                    case 3:
                        hasData = _a.sent();
                        shouldDelete = !hasActiveTab && !hasData;
                        if (!shouldDelete) return [3, 7];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4, this.sessionsTable.delete(session.uuid)];
                    case 5:
                        _a.sent();
                        return [3, 7];
                    case 6:
                        err_1 = _a.sent();
                        return [3, 7];
                    case 7:
                        _i++;
                        return [3, 1];
                    case 8: return [2];
                }
            });
        });
    };
    StorageProcessor.prototype.hasData = function (session) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, logsCount, issuesCount, deviceKeysCount, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, Promise.all([
                                this.logsTable.count(session.uuid),
                                this.issuesTable.count(session.uuid),
                                this.deviceKeysTable.count(session.uuid),
                            ])];
                    case 1:
                        _a = _b.sent(), logsCount = _a[0], issuesCount = _a[1], deviceKeysCount = _a[2];
                        return [2, logsCount > 0 || issuesCount > 0 || deviceKeysCount > 0];
                    case 2:
                        err_2 = _b.sent();
                        return [2, true];
                    case 3: return [2];
                }
            });
        });
    };
    StorageProcessor.prototype.registerSession = function (sessionIDB) {
        return __awaiter(this, void 0, void 0, function () {
            var session, id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!sessionIDB.id) return [3, 2];
                        session = this.sessionFactory.create(sessionIDB.uuid, this.options);
                        return [4, this.api.sendSession(session)];
                    case 1:
                        id = (_a.sent()).id;
                        sessionIDB.id = id;
                        this.sessionsTable.update(sessionIDB.uuid, { id: id });
                        _a.label = 2;
                    case 2: return [2, sessionIDB];
                }
            });
        });
    };
    StorageProcessor.prototype.canRun = function () {
        return (this.connection.isOnline() &&
            this.channel.isLeader() &&
            this.storageGuard.canProcess(this.forceSend) &&
            !this.isRunning);
    };
    StorageProcessor.prototype.run = function () {
        return __awaiter(this, void 0, void 0, function () {
            var canRun, sessions, _i, sessions_2, session;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        canRun = this.canRun();
                        this.logger.log('Processing...', {
                            forceSend: this.forceSend,
                            isRunning: this.isRunning,
                            isLeader: this.channel.isLeader(),
                            isOnline: this.connection.isOnline(),
                            canRun: canRun,
                        });
                        if (!canRun) {
                            this.logger.log('Aborted.');
                            return [2];
                        }
                        this.isRunning = true;
                        return [4, this.sessionsTable.getAll()];
                    case 1:
                        sessions = _a.sent();
                        this.logger.log(sessions.length + " session/s found");
                        _i = 0, sessions_2 = sessions;
                        _a.label = 2;
                    case 2:
                        if (!(_i < sessions_2.length)) return [3, 7];
                        session = sessions_2[_i];
                        return [4, this.registerSession(session)];
                    case 3:
                        session = _a.sent();
                        this.logger.log("Processing Session ID: " + session.id);
                        return [4, this.sendLogs(session)];
                    case 4:
                        _a.sent();
                        return [4, this.sendIssues(session)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        _i++;
                        return [3, 2];
                    case 7: return [4, this.sendDeviceData()];
                    case 8:
                        _a.sent();
                        return [4, this.cleanupSessions(sessions)];
                    case 9:
                        _a.sent();
                        this.forceSend = false;
                        this.isRunning = false;
                        this.logger.log('Done');
                        return [2];
                }
            });
        });
    };
    StorageProcessor.prototype.sendDeviceData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deviceKeyChanges_1, deviceKeys, err_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        deviceKeyChanges_1 = {};
                        return [4, this.deviceKeysTable.getAll()];
                    case 1:
                        deviceKeys = _a.sent();
                        deviceKeys.forEach(function (entry) {
                            deviceKeyChanges_1[entry.data.key] = entry.data.value;
                        });
                        if (!deviceKeys.length) return [3, 4];
                        return [4, Promise.all(Object.entries(deviceKeyChanges_1)
                                .map(function (_a) {
                                var key = _a[0], value = _a[1];
                                return _this.api.setDeviceKey(_this.device.getUDID(), key, value);
                            }))];
                    case 2:
                        _a.sent();
                        return [4, this.deviceKeysTable.deleteBatch(deviceKeys.length)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3, 6];
                    case 5:
                        err_3 = _a.sent();
                        this.logger.error('sendDeviceData error', err_3);
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    StorageProcessor.prototype.sendIssues = function (session) {
        return __awaiter(this, void 0, void 0, function () {
            var entries, _i, entries_1, entry, issue, issueURL, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4, this.issuesTable.getBatch(session.uuid, this.BATCH_SIZE)];
                    case 1:
                        entries = _a.sent();
                        _i = 0, entries_1 = entries;
                        _a.label = 2;
                    case 2:
                        if (!(_i < entries_1.length)) return [3, 6];
                        entry = entries_1[_i];
                        issue = entry.data;
                        issueURL = this.options.baseURL + "/intent/" + this.options.appID + "/issue/" + issue.uuid;
                        return [4, this.api.sendIssue(issue.uuid, issueURL, issue.title, issue.markdown, issue.type, session.id)];
                    case 3:
                        _a.sent();
                        return [4, this.issuesTable.deleteById(entry.id)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3, 2];
                    case 6: return [3, 8];
                    case 7:
                        err_4 = _a.sent();
                        this.logger.error('sendIssues error', err_4);
                        return [3, 8];
                    case 8: return [2];
                }
            });
        });
    };
    StorageProcessor.prototype.sendLogs = function (session) {
        return __awaiter(this, void 0, void 0, function () {
            var entries, logs, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4, this.logsTable.getBatch(session.uuid, this.BATCH_SIZE)];
                    case 1:
                        entries = _a.sent();
                        logs = entries.map(function (entry) { return entry.data; });
                        this.logger.log('sendLogs', { entries: entries, logs: logs });
                        if (!logs.length) return [3, 4];
                        return [4, this.api.sendLogs(session.id, logs)];
                    case 2:
                        _a.sent();
                        return [4, this.logsTable.deleteBatch(session.uuid, logs.length)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3, 6];
                    case 5:
                        err_5 = _a.sent();
                        this.logger.error('sendLogs error', err_5);
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    return StorageProcessor;
}());
exports.StorageProcessor = StorageProcessor;
