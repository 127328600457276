"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoopLogger = exports.ConsoleLogger = void 0;
var ConsoleLogger = (function () {
    function ConsoleLogger(console, tag) {
        this.console = console;
        this.tag = tag;
    }
    ConsoleLogger.prototype.error = function (msg, data) {
        var tag = this.tag ? "[" + this.tag + "] " : '';
        msg = "[BF-SDK]" + tag + msg;
        if (data) {
            this.console.log(msg, data);
        }
        else {
            this.console.log(msg);
        }
    };
    ConsoleLogger.prototype.getTagged = function (tag) {
        return new ConsoleLogger(this.console, tag);
    };
    ConsoleLogger.prototype.log = function (msg, data) {
        var tag = this.tag ? "[" + this.tag + "] " : '';
        msg = "[BF-SDK]" + tag + msg;
        if (data) {
            this.console.log(msg, data);
        }
        else {
            this.console.log(msg);
        }
    };
    return ConsoleLogger;
}());
exports.ConsoleLogger = ConsoleLogger;
var NoopLogger = (function () {
    function NoopLogger() {
    }
    NoopLogger.prototype.error = function () {
        return;
    };
    NoopLogger.prototype.getTagged = function () {
        return new NoopLogger();
    };
    NoopLogger.prototype.log = function () {
        return;
    };
    return NoopLogger;
}());
exports.NoopLogger = NoopLogger;
