"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentSession = void 0;
var storage_keys_1 = require("./storage-keys");
var CurrentSession = (function () {
    function CurrentSession(device, logger, sessionStorage, sessionsTable, uuidFactory) {
        this.device = device;
        this.logger = logger;
        this.sessionStorage = sessionStorage;
        this.sessionsTable = sessionsTable;
        this.uuidFactory = uuidFactory;
        this.reset();
    }
    CurrentSession.prototype.getUUID = function () {
        return this.uuid;
    };
    CurrentSession.prototype.initSession = function () {
        var deviceUDID = this.device.getUDID();
        this.logger.log('Init session');
        this.uuid = this.uuidFactory.create();
        this.sessionStorage.setItem(storage_keys_1.SessionStorageKey.SessionUUID, this.uuid);
        this.sessionStorage.setItem(storage_keys_1.SessionStorageKey.SessionUDID, deviceUDID);
    };
    CurrentSession.prototype.reset = function () {
        this.uuid = this.sessionStorage.getItem(storage_keys_1.SessionStorageKey.SessionUUID);
        if (this.uuid === null) {
            this.logger.log('Session not available');
            this.initSession();
        }
        else {
            var sessionDeviceUDID = this.sessionStorage.getItem(storage_keys_1.SessionStorageKey.SessionUDID);
            var hasDeviceUDIDChanged = sessionDeviceUDID !== this.device.getUDID();
            if (hasDeviceUDIDChanged) {
                this.logger.log('Session Device UDID changed');
                this.initSession();
            }
            else {
                this.logger.log("Session UUID: " + this.uuid);
            }
        }
        this.sessionsTable.addIfMissing({
            uuid: this.uuid,
            udid: this.device.getUDID(),
        });
    };
    return CurrentSession;
}());
exports.CurrentSession = CurrentSession;
