"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryLogsTable = exports.IDBLogsTable = void 0;
var IDBLogsTable = (function () {
    function IDBLogsTable(logs, logFactory) {
        this.logs = logs;
        this.logFactory = logFactory;
    }
    IDBLogsTable.prototype.add = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.logs.add(item)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IDBLogsTable.prototype.count = function (sessionUUID) {
        return this.logs.where({ sessionUUID: sessionUUID }).count();
    };
    IDBLogsTable.prototype.deleteBatch = function (sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.logs.where({ sessionUUID: sessionUUID }).limit(batchSize).delete()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IDBLogsTable.prototype.deleteOldLogs = function (sessionUUID) {
        return __awaiter(this, void 0, void 0, function () {
            var db;
            var _this = this;
            return __generator(this, function (_a) {
                db = this.logs.db;
                return [2, db.transaction('rw', this.logs, function () { return __awaiter(_this, void 0, void 0, function () {
                        var firstEntryKey, logs, log;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, this.logs.where({ sessionUUID: sessionUUID }).limit(1).primaryKeys()];
                                case 1:
                                    firstEntryKey = (_a.sent())[0];
                                    return [4, this.logs.where({ sessionUUID: sessionUUID }).limit(10).toArray()];
                                case 2:
                                    logs = _a.sent();
                                    if (logs.length <= 1) {
                                        return [2];
                                    }
                                    log = {
                                        sessionUUID: sessionUUID,
                                        data: this.logFactory.createGapLog(logs.map(function (e) { return e.data; })),
                                    };
                                    return [4, this.logs.update(firstEntryKey, log)];
                                case 3:
                                    _a.sent();
                                    return [4, this.logs.where({ sessionUUID: sessionUUID }).offset(1).limit(9).delete()];
                                case 4:
                                    _a.sent();
                                    return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    IDBLogsTable.prototype.getBatch = function (sessionUUID, batchSize) {
        return this.logs.where({ sessionUUID: sessionUUID }).limit(batchSize).toArray();
    };
    return IDBLogsTable;
}());
exports.IDBLogsTable = IDBLogsTable;
var InMemoryLogsTable = (function () {
    function InMemoryLogsTable() {
        this.data = [];
        this.idCounter = 0;
    }
    InMemoryLogsTable.prototype.getNextID = function () {
        return this.idCounter++;
    };
    InMemoryLogsTable.prototype.add = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var id;
            return __generator(this, function (_a) {
                id = this.getNextID();
                this.data.push(__assign(__assign({}, item), { id: id }));
                return [2];
            });
        });
    };
    InMemoryLogsTable.prototype.count = function (sessionUUID) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.data.filter(function (d) { return d.sessionUUID === sessionUUID; }).length];
            });
        });
    };
    InMemoryLogsTable.prototype.deleteBatch = function (sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function () {
            var data, counter, i, log;
            return __generator(this, function (_a) {
                data = [];
                counter = 0;
                for (i = 0; i < this.data.length; i++) {
                    log = this.data[i];
                    if (log.sessionUUID === sessionUUID && counter < batchSize) {
                        counter++;
                    }
                    else {
                        data.push(log);
                    }
                }
                this.data = data;
                return [2];
            });
        });
    };
    InMemoryLogsTable.prototype.deleteOldLogs = function (_sessionUUID) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Method not implemented');
            });
        });
    };
    InMemoryLogsTable.prototype.getBatch = function (sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.data.filter(function (d) { return d.sessionUUID === sessionUUID; }).slice(0, batchSize)];
            });
        });
    };
    return InMemoryLogsTable;
}());
exports.InMemoryLogsTable = InMemoryLogsTable;
