"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterEventsHandlerBase = void 0;
var RegisterEventsHandlerBase = (function () {
    function RegisterEventsHandlerBase(window) {
        this.window = window;
    }
    RegisterEventsHandlerBase.prototype.getURL = function () {
        if (this.window) {
            return this.window.location.href;
        }
        return '';
    };
    RegisterEventsHandlerBase.prototype.supportsPassiveEvents = function () {
        var passiveSupported = false;
        try {
            var options = {
                get passive() {
                    passiveSupported = true;
                    return false;
                }
            };
            this.window.addEventListener('test', null, options);
            this.window.removeEventListener('test', null, false);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    };
    return RegisterEventsHandlerBase;
}());
exports.RegisterEventsHandlerBase = RegisterEventsHandlerBase;
