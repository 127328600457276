"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriter = void 0;
var StorageWriter = (function () {
    function StorageWriter(deviceKeysTable, issuesTable, logFactory, logsTable) {
        this.deviceKeysTable = deviceKeysTable;
        this.issuesTable = issuesTable;
        this.logFactory = logFactory;
        this.logsTable = logsTable;
        this.isClearingSpace = false;
        this.logsQueue = [];
    }
    StorageWriter.prototype.handleMessage = function (msg) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (msg.type) {
                    case 'device-key':
                        return [2, this.addDeviceKey(msg.data.key, msg.data.value, msg.data.callInfo)];
                    case 'init': {
                        this.options = msg.data.options;
                        this.sessionUUID = msg.data.sessionUUID;
                        break;
                    }
                    case 'issue':
                        return [2, this.addIssue(msg.data.type, msg.data.title, msg.data.markdown, msg.data.uuid, msg.data.callInfo)];
                    case 'log-entry':
                        return [2, this.addLogEntry(msg.data.log, msg.data.callInfo)];
                }
                return [2];
            });
        });
    };
    StorageWriter.prototype.addDeviceKey = function (key, value, callInfo) {
        this.deviceKeysTable.add({
            sessionUUID: this.sessionUUID,
            data: { key: key, value: value },
        });
        this.addLog(this.logFactory.createFromDeviceKey(key, value, callInfo));
    };
    StorageWriter.prototype.addLog = function (log) {
        return __awaiter(this, void 0, void 0, function () {
            var entry, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        entry = {
                            sessionUUID: this.sessionUUID,
                            data: log,
                        };
                        if (!this.isClearingSpace) return [3, 1];
                        this.logsQueue.push(entry);
                        return [3, 7];
                    case 1:
                        _a.trys.push([1, 3, , 7]);
                        return [4, this.logsTable.add(entry)];
                    case 2:
                        _a.sent();
                        return [3, 7];
                    case 3:
                        err_1 = _a.sent();
                        if (!this.isQuotaExceededError(err_1)) return [3, 6];
                        this.logsQueue.push(entry);
                        this.isClearingSpace = true;
                        return [4, this.logsTable.deleteOldLogs(this.sessionUUID)];
                    case 4:
                        _a.sent();
                        return [4, this.processLogsQueue()];
                    case 5:
                        _a.sent();
                        this.isClearingSpace = false;
                        _a.label = 6;
                    case 6: return [3, 7];
                    case 7: return [2];
                }
            });
        });
    };
    StorageWriter.prototype.addLogEntry = function (log, callInfo) {
        this.addLog(this.logFactory.createFromEntry(log, callInfo));
    };
    StorageWriter.prototype.addIssue = function (type, title, markdown, uuid, callInfo) {
        this.issuesTable.add({
            sessionUUID: this.sessionUUID,
            data: { markdown: markdown, title: title, type: type, uuid: uuid },
        });
        this.addLog(this.logFactory.createFromIssue(uuid, callInfo));
    };
    StorageWriter.prototype.isQuotaExceededError = function (err) {
        var _a;
        return ((err.name === 'QuotaExceededError') ||
            (((_a = err.inner) === null || _a === void 0 ? void 0 : _a.name) === 'QuotaExceededError'));
    };
    StorageWriter.prototype.processLogsQueue = function () {
        return __awaiter(this, void 0, void 0, function () {
            var entry, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.logsQueue.length > 0)) return [3, 7];
                        entry = this.logsQueue[0];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 6]);
                        return [4, this.logsTable.add(entry)];
                    case 2:
                        _a.sent();
                        this.logsQueue.shift();
                        return [3, 6];
                    case 3:
                        err_2 = _a.sent();
                        if (!this.isQuotaExceededError(err_2)) return [3, 5];
                        return [4, this.logsTable.deleteOldLogs(this.sessionUUID)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3, 6];
                    case 6: return [3, 0];
                    case 7: return [2];
                }
            });
        });
    };
    return StorageWriter;
}());
exports.StorageWriter = StorageWriter;
